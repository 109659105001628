import React, { useState, useEffect } from 'react';
import axios from 'axios'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { none } from 'ol/centerconstraint';
import { Box } from '@mui/material';

export const TwitchClips = () => {

    // const [ twitchClips, updateTwitchClips] = useState([])

    // useEffect(()=> {
    //     getAllClips()
    // })

    // const getAllClips = () => {
    //     axios({
    //         url: "https://um5do6okcl.execute-api.us-west-2.amazonaws.com/default/fetchTwitchClipsByUser?TableName=twitch_creator_db",
    //         method: 'GET',
    //         headers: {
    //             'x-api-key': 'ptRrzOlSPTrrmdnvpMuk7PY1SjB9P4k7pB8I1bPf',
    //             'Access-Control-Allow-Origin': "https://um5do6okcl.execute-api.us-west-2.amazonaws.com"
    //             }
    //     }).then(response => updateTwitchClips(response["Items"].map(item => [item.twitchClipUrl, ...twitchClips])))
    // }

    // console.log(twitchClips)

    return(
            <Box sx={{display: 'table', top: 0, left: 0, height: '50%', width: '75%'}}>
                {/* Regular Layout */}
                <Box sx={{display: {xs: 'none', sm: 'table-cell'}, verticalAlign: 'middle'}}>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', width: '100%'}}>
                        <Carousel showArrows={true} infiniteLoop={true} showStatus={false}>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=NeighborlyInterestingBaconBigBrother-LdG0SxNPk3XxVqK3&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=NeighborlyInterestingBaconBigBrother-LdG0SxNPk3XxVqK3&parent=bammer.gg"
                                height="550px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=DarkPoorSpindleSSSsss-4Sa2zmCw28edCSQ-&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=DarkPoorSpindleSSSsss-4Sa2zmCw28edCSQ-&parent=bammer.gg"
                                height="550px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=SeductiveOriginalScorpionKeyboardCat-agUehOaGzOxx3Atq&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=SeductiveOriginalScorpionKeyboardCat-agUehOaGzOxx3Atq&parent=bammer.gg"
                                height="550px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                src="https://www.youtube.com/embed/RWQ1lPX7Cy8"
                                height="550px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                        </Carousel>
                    </div>
                </Box>
                {/* Mobile Layout */}
                <Box sx={{display: {xs: 'table-cell', sm: 'none'}, verticalAlign: 'middle'}}>
                    <div style={{marginLeft: '-4%', marginRight: 'auto', width: '65%'}}>
                        <Carousel showArrows={true} infiniteLoop={true} showStatus={false}>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=NeighborlyInterestingBaconBigBrother-LdG0SxNPk3XxVqK3&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=NeighborlyInterestingBaconBigBrother-LdG0SxNPk3XxVqK3&parent=bammer.gg"
                                height="150px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=DarkPoorSpindleSSSsss-4Sa2zmCw28edCSQ-&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=DarkPoorSpindleSSSsss-4Sa2zmCw28edCSQ-&parent=bammer.gg"
                                height="150px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                //src="https://clips.twitch.tv/embed?clip=SeductiveOriginalScorpionKeyboardCat-agUehOaGzOxx3Atq&parent=localhost"
                                src="https://clips.twitch.tv/embed?clip=SeductiveOriginalScorpionKeyboardCat-agUehOaGzOxx3Atq&parent=bammer.gg"
                                height="150px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                            <iframe
                                //USE THIS SOURCE WHEN DEVELOPING LOCALLY
                                src="https://www.youtube.com/embed/RWQ1lPX7Cy8"
                                height="150px"
                                width="100%"
                                allowfullscreen="false">
                            </iframe>
                        </Carousel>
                    </div>
                </Box>
            </Box>
           
    )
}