import * as React from "react"
import { Header } from "../components/header"
import { TwitchClips } from "../components/twitch-clips"
import { StaticImage } from "gatsby-plugin-image"

import '../styles/clips.css'
import { Box, Divider, Grid } from "@mui/material"
import ClipsSocials from "../components/clips-socials"

import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const ClipsPage = () => {

  const { placeholderImage, mobilePlaceholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "background2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 4096
              height: 4096
              placeholder: BLURRED
            )
          }
        },
        mobilePlaceholderImage: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 650
              height: 1300
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)
  const mobileImage = getImage(mobilePlaceholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)
  const mobileBGImage = convertToBgImage(mobileImage)

  return (
      <div>
        <Header></Header>
        {/* Regular Display */}
        <Box sx={{ display: {xs: 'none', sm: "grid"}, boxSizing: 'border-box' }}>
          <div style={{gridArea: "1/1",
                    position: "relative",
                    display: "grid",
                    }}>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              style={{backgroundRepeat: 'repeat-y'}}
            >
              <GatsbyImage image={image} alt={"testimage"}/>
            </BackgroundImage>
          </div>
          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              placeItems: "center",
              display: "grid",
            }}
          >
            <Box sx={{ display: 'flex', height: '100%', width: '65%', backgroundColor: '#0B2545'}}>
              <Grid container style={{justifyContent: 'center', display: 'flex'}}>
                <Grid item sm={12}>
                  <div style={{ marginTop: '10rem', display: 'flex', justifyContent: 'center'}}>
                    <TwitchClips />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ height: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 style={{color: "#F3EFE0"}}>Check out the socials for our streamers!</h1>
                  </Box>
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={4} style={{ height: 'fit-content'}}>
                  {/* twitch and tiktok */}
                  <ClipsSocials streamerName="xBacillus" twitch="https://twitch.tv/xBacillus" tiktok="https://tiktok.com/@xbacillus"  />
                </Grid>
                <Grid item sm={4} style={{ height: 'fit-content'}}>
                  {/* twitch and youtube */}
                  <ClipsSocials streamerName="Chavise" twitch="https://twitch.tv/chavise" youtube="https://www.youtube.com/channel/UC4vIcMvwM4IRpCuYYWUnHQQ" />
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={12}>
                  <Divider orientation="horizontal" style={{marginTop: '1rem', borderColor: '#F3EFE0'}} />
                </Grid>
                <Grid item sm={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="Ashravens"  twitch="https://twitch.tv/ashravens" />
                </Grid>
                <Grid item sm={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="EasyBreezyEgg" twitch="https://twitch.tv/easybreezyegg" />
                </Grid>
                <Grid item sm={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="Gojira" twitch="https://twitch.tv/gojiiraa" />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Box>
        {/* Mobile Layout */}
        <Box sx={{ display: {xs: 'grid', sm: "none"}, boxSizing: 'border-box', backgroundColor: '#0B2545' }}>
          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              placeItems: "center",
              display: "grid",
              marginTop: '10rem'
            }}
          >
            {/* <Box sx={{ display: 'flex', height: '100%', width: '65%', backgroundColor: '#0B2545'}}> */}
              <Grid container style={{width: '100%', display: 'flex', overflow: 'hidden'}}>
                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <TwitchClips />
                </Grid>
                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <h1 style={{color: "#F3EFE0"}}>Check out the socials for our streamers!</h1>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} style={{ height: 'fit-content'}}>
                  {/* twitch and tiktok */}
                  <ClipsSocials streamerName="xBacillus" twitch="https://twitch.tv/xBacillus" tiktok="https://tiktok.com/@xbacillus"  />
                </Grid>
                <Grid item xs={4} style={{ height: 'fit-content'}}>
                  {/* twitch and youtube */}
                  <ClipsSocials streamerName="Chavise" twitch="https://twitch.tv/chavise" youtube="https://www.youtube.com/channel/UC4vIcMvwM4IRpCuYYWUnHQQ" />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={12}>
                  <Divider orientation="horizontal" style={{marginTop: '3rem', borderColor: '#F3EFE0'}} />
                </Grid>
                <Grid item xs={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="Ashravens"  twitch="https://twitch.tv/ashravens" />
                </Grid>
                <Grid item xs={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="EasyBreezyEgg" twitch="https://twitch.tv/easybreezyegg" />
                </Grid>
                <Grid item xs={4} style={{ height: 'fit-content'}}>
                  {/* twitch */}
                  <ClipsSocials streamerName="Gojira" twitch="https://twitch.tv/gojiiraa" />
                </Grid>
              </Grid>
            {/* </Box> */}
          </div>
        </Box>
      </div>
  )
}

export default ClipsPage
