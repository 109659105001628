import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {Grid} from "@mui/material"

const ClipsSocials = ({streamerName, twitch, tiktok, youtube}) => {

    const [socialList, setSocialList] = React.useState([]);

    React.useEffect(() => {

        const list = [];
        
        if(twitch) {
            list.push({type: 'twitch', link: twitch})
        }
        if(tiktok) {
            list.push({type: 'tiktok', link: tiktok})
        }
        if(youtube) {
            list.push({type: 'youtube', link: youtube})
        }

        setSocialList(list);

    }, []);

    return (
        <Grid container style={{ marginTop: '1rem', height: "fit-content", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Grid item xs={12}>
                <h2 style={{color: "#F3EFE0"}}>{streamerName}</h2>
            </Grid>
            <Grid item xs={12}>
                {socialList.length === 1 &&
                    socialList.map((socials) => (
                        <Grid container>
                            <Grid item xs={12} style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                {socials.type === 'twitch' && 
                                    <Link to={socials.link} target="_blank">
                                        <StaticImage src="../images/socials/eggshell/twitch.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                                    </Link>
                                }
                            </Grid>
                        </Grid>
                    ))
                }
                {socialList.length === 2 &&
                    <Grid container>
                        {socialList.map((socials) => (
                            <Grid item xs={5}>
                                <Grid container>
                                    <Grid item xs={6} />
                                    <Grid item xs={4} style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                        {socials.type === 'twitch' && 
                                            <Link to={socials.link} target="_blank">
                                                <StaticImage src="../images/socials/eggshell/twitch.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                                            </Link>
                                        }
                                        {socials.type === 'youtube' && 
                                            <Link to={socials.link} target="_blank">
                                                <StaticImage src="../images/socials/eggshell/youtube.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                                            </Link>
                                        }
                                        {socials.type === 'tiktok' && 
                                            <Link to={socials.link} target="_blank">
                                                <StaticImage src="../images/socials/eggshell/tiktok.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                                            </Link>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                }
                {/* <Grid item sm={4} style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <Link to="/about/nick-j">
                        <StaticImage src="../images/socials/eggshell/instagram.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                    </Link>
                </Grid> */}
                {/* <Grid item sm={6} style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <Link to="/about/nick-j">
                        <StaticImage src="../images/socials/eggshell/twitter.png" style={{marginTop: '2rem', height: '25px', width: '25px'}} height={25} />
                    </Link>
                </Grid> */}
                </Grid>
        </Grid>
    )
}

export default ClipsSocials